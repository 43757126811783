export const schemaSeoConfig = ($domainUrl: string, $imageUrl: string) => [
  JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: 'DU88 - Nhà Cái Thể Thao, Bóng Đá, Live Casino Trực Tuyến',
      brand: 'DU88',
      logo: `${$imageUrl}/assets/brand/logo.webp`,
      image: `${$imageUrl}/assets/brand/seo-image.webp?h=${generateHash(3)}`,
      mainEntityOfPage: `${$domainUrl}/`,
      description: 'DU88 là nhà cái cá cược bóng đá, thể thao, live casino, lô đề, siêu chợ games đổi thưởng uy tín và có tỷ lệ hoàn trả cao nhất thị trường.',
      '@id': `${$domainUrl}/`,
      url: `${$domainUrl}/`,
      telephone: '098553321',
      priceRange: '$',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '582a Đ. Quang Trung, Phường 11, Gò Vấp, Hồ Chí Minh, Việt Nam',
        addressLocality: 'Hồ Chí Minh',
        postalCode: '700000',
        addressCountry: 'Việt Nam'
      },
      openingHoursSpecification: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ],
        opens: '00:00',
        closes: '23:59'
      },
      sameAs: [
        'https://www.youtube.com/@du88win',
        'https://x.com/du88win',
        'https://sites.google.com/view/du88win',
        'https://www.linkedin.com/in/du88win/',
        'https://www.pinterest.com/du88win/',
        'https://www.tumblr.com/du88win',
        'https://www.instapaper.com/p/du88win',
        'https://www.twitch.tv/du88win/about',
        'https://www.reddit.com/user/du88win/',
        'https://www.blogger.com/profile/13243732931329192158',
        'https://vimeo.com/du88win',
        'https://www.deviantart.com/du88win',
        'https://www.behance.net/du88win',
        'https://os.mbed.com/users/du88win/',
        'https://www.bitchute.com/channel/cytDgmeB9cjC',
        'https://qiita.com/du88win',
        'https://all4webs.com/du88win/home.htm?60629=21818',
        'https://www.sidefx.com/profile/du88win/',
        'https://www.cfd-online.com/Forums/members/du88win.html',
        'https://www.renderosity.com/users/id:1593638',
        'https://www.snipesocial.co.uk/du88win',
        'https://participa.aytoreinosa.es/profiles/du88win/activity',
        'https://awan.pro/forum/user/17256/',
        'https://beteiligung.stadtlindau.de/profile/du88win/',
        'https://homepage.ninja/du88win',
        'https://jobs.votesaveamerica.com/profiles/5609856-du88-win',
        'https://gravatar.com/du88win',
        'https://socialsocial.social/user/du88win/',
        'https://pubhtml5.com/homepage/owlij/',
        'https://tupalo.com/en/users/7854686',
        'https://www.speedrun.com/users/du88win',
        'https://www.undrtone.com/du88win',
        'http://www.askmap.net/location/7169057/vietnam/du88win',
        'https://www.jobscoop.org/profiles/5609898-du88-win',
        'https://stocktwits.com/du88win',
        'https://myfreelancerbook.com/page/sports/du88win',
        'https://help.orrs.de/user/du88win/wall',
        'https://www.mtg-forum.de/user/101288-du88win/',
        'https://code.antopie.org/du88win',
        'https://www.homepokergames.com/vbforum/member.php?u=121803',
        'https://gitlab.vuhdo.io/du88win',
        'https://xtremepape.rs/members/du88win.493583/#about',
        'https://decidim.santcugat.cat/profiles/du88win/activity',
        'https://jobs.landscapeindustrycareers.org/profiles/5609955-du88-win',
        'https://wefunder.com/du88win',
        'http://80.82.64.206/user/du88win',
        'http://www.chambresapart.fr/user/uyendao106',
        'http://emseyi.com/user/du88win',
        'https://app.talkshoe.com/user/du88win',
        'https://ingmac.ru/forum/?PAGE_NAME=profile_view&UID=64781',
        'https://www.callupcontact.com/b/businessprofile/du88win/9381478',
        'https://app.geniusu.com/users/2556120',
        'https://scrapbox.io/du88win/du88win',
        'https://community.alexgyver.ru/members/du88win.85306/#about',
        'https://edabit.com/user/iWggYLJHNXB33RaFN',
        'http://forum.concord.com.tr/user-17227.html',
        'https://dreevoo.com/profile_info.php?pid=711969',
        'https://blender.community/du88win/',
        'https://wiki.natlife.ru/index.php/%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA:Du88win',
        'http://www.aunetads.com/view/item-2521451-du88win.html',
        'https://topsitenet.com/profile/du88win/1312587/',
        'https://pastelink.net/du88win',
        'https://snippet.host/rovvvh',
        'https://hackmd.okfn.de/s/rJXhksYGkg',
        'https://justpaste.me/DGe01',
        'https://transfur.com/Users/du88win',
        'https://www.syncdocs.com/forums/profile/du88win',
        'https://www.faneo.es/users/du88win/',
        'https://www.bondhuplus.com/du88win',
        'https://old.bitchute.com/channel/cytDgmeB9cjC/',
        'https://www.songback.com/profile/10192/about',
        'https://modworkshop.net/user/du88win',
        'https://slidehtml5.com/homepage/baeu#About',
        'https://www.magcloud.com/user/du88win',
        'https://www.dermandar.com/user/du88win/',
        'https://my.djtechtools.com/users/1466981',
        'https://pytania.radnik.pl/uzytkownik/du88win/wall',
        'https://club.doctissimo.fr/du88win/',
        'https://wiki.gta-zona.ru/index.php/%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA:Du88win',
        'https://osallistu.siilinjarvi.fi/profiles/du88win/activity',
        'http://fort-raevskiy.ru/community/profile/du88win/',
        'https://www.claimajob.com/profiles/5610389-du88-win',
        'https://uccle.monopinion.belgium.be/profiles/du88win/activity',
        'https://participa.terrassa.cat/profiles/du88win/activity',
        'https://divisionmidway.org/jobs/author/du88win/',
        'https://jobs.lajobsportal.org/profiles/5610415-du88-win',
        'https://www.heavyironjobs.com/profiles/5610435-du88-win',
        'http://ww.metanotes.com/user/du88win',
        'https://mto.to/u/2307009-du88win',
        'https://golosknig.com/profile/du88win/',
        'http://www.invelos.com/UserProfile.aspx?Alias=du88win',
        'https://phatwalletforums.com/user/du88win',
        'https://www.bandlab.com/du88win',
        'https://www.elephantjournal.com/profile/du88win/',
        'https://menta.work/user/140302',
        'https://nhattao.com/members/user6627881.6627881/',
        'https://gitlab.xfce.org/du88win',
        'https://swebbtube.se/a/du88win/video-channels',
        'https://aprenderfotografia.online/usuarios/du88win/profile/',
        'https://www.passes.com/du88win',
        'https://www.strata.com/forums/users/du88win/',
        'https://www.webwiki.co.uk/du88.win',
        'https://secondstreet.ru/profile/du88win/',
        'https://www.jumpinsport.com/users/du88win',
        'https://manylink.co/@du88win',
        'https://du88win.gallery.ru/',
        'https://next.nexusmods.com/profile/du88win',
        'https://glose.com/u/du88win',
        'https://wakelet.com/@du88win',
        'https://www.xen-factory.com/index.php?members/du88win.61819/#about',
        'https://www.myminifactory.com/users/du88win',
        'https://starity.hu/profil/510382-du88win/',
        'https://gifyu.com/du88win',
        'https://pxhere.com/en/photographer/4438118',
        'https://app.roll20.net/users/15225023/du88win',
        'https://leetcode.com/u/du88win/',
        'https://www.walkscore.com/people/416315609611/du88win',
        'https://disqus.com/by/du88win/about/',
        'https://vi.gravatar.com/du88win',
        'http://worldchampmambo.com/UserProfile/tabid/42/userId/406546/Default.aspx',
        'https://du88win.bandcamp.com/album/du88win',
        'https://pixabay.com/es/users/47159573/',
        'https://gitlab.com/du88win',
        'https://www.mixcloud.com/du88win/',
        'https://hub.docker.com/u/du88win',
        'https://community.cisco.com/t5/user/viewprofilepage/user-id/1814222',
        'https://du88win.creator-spring.com/',
        'https://website.informer.com/du88.win',
        'https://hto.to/u/2307009-du88win',
        'https://www.besport.com/group/1297731',
        'https://www.picmix.com/profile/du88win',
        'https://safechat.com/u/du88win',
        'https://hashnode.com/@du88win',
        'https://beacons.ai/du88win',
        'https://muckrack.com/du88-win/bio',
        'http://maisoncarlos.com/UserProfile/tabid/42/userId/2240839/Default.aspx',
        'https://golbis.com/user/du88win/',
        'https://forum.index.hu/User/UserDescription?u=2042434',
        'https://forum.codeigniter.com/member.php?action=profile&uid=136008',
        'https://www.webwiki.de/du88.win',
        'https://www.silverstripe.org/ForumMemberProfile/show/190039',
        'https://mssg.me/du88win',
        'https://www.intensedebate.com/people/du88win1',
        'http://wiki.diamonds-crew.net/index.php?title=Benutzer:Du88win',
        'https://socialtrain.stage.lithium.com/t5/user/viewprofilepage/user-id/115972',
        'https://files.fm/du88win/info',
        'https://www.bricklink.com/aboutMe.asp?u=du88win',
        'https://booklog.jp/users/du88win/profile',
        'https://wiki.prochipovan.ru/index.php/%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA:Du88win',
        'https://www.symbaloo.com/mix/du88win',
        'https://start.me/w/e70djx',
        'https://www.longisland.com/profile/du88win',
        'https://forums.alliedmods.net/member.php?u=397027',
        'http://psicolinguistica.letras.ufmg.br/wiki/index.php/Usu%C3%A1rio:Du88win',
        'https://www.anobii.com/en/01063aa5cf2d8cee1d/profile/activity',
        'https://www.webwiki.ch/du88.win',
        'https://forum.repetier.com/profile/du88win',
        'https://herbalmeds-forum.biolife.com.my/d/207657-du88win',
        'https://www.beamng.com/members/du88win.656543/',
        'https://fyers.in/community/member/ZGNznR5nsW',
        'https://myapple.pl/users/480151-du88win',
        'https://conecta.bio/du88win',
        'https://linkin.bio/du88win/',
        'https://www.bigoven.com/user/du88win',
        'https://community.m5stack.com/user/du88win',
        'https://www.pintradingdb.com/forum/member.php?action=profile&uid=86621',
        'https://www.criminalelement.com/members/du88win/profile/',
        'https://samkey.org/forum/member.php?310423-du88win',
        'https://lifeinsys.com/user/du88win',
        'https://biiut.com/du88win',
        'https://f319.com/members/du88win.887143/',
        'https://cdn.muvizu.com/Profile/du88win/Latest',
        'https://boersen.oeh-salzburg.at/author/du88win/',
        'https://6giay.vn/members/du88win.105421/',
        'https://vjudge.net/user/du88win',
        'https://www.akaqa.com/account/profile/19191684030',
        'https://www.titantalk.com/members/du88win.390152/',
        'https://www.familie.pl/profil/du88win',
        'https://forum.dmec.vn/index.php?members/du88win.86233/',
        'https://web.trustexchange.com/company.php?q=du88.win',
        'https://www.aoezone.net/members/du88win.133102/#about',
        'https://community.stencyl.com/index.php?action=profile;u=1246935',
        'https://stepik.org/users/993710903/profile',
        'https://savee.it/du88win123/',
        'https://community.liquid.net/u/260cb7ec',
        'http://www.biblesupport.com/user/613808-du88win/',
        'https://imgcredit.xyz/du88win',
        'https://mlx.su/paste/view/17cbd77d',
        'https://www.herlypc.es/community/profile/du88win/',
        'https://www.royalroad.com/profile/590004',
        'http://du88win.geoblog.pl/',
        'https://www.timessquarereporter.com/profile/du88win',
        'https://www.mississaugachinese.net/home.php?mod=space&uid=1350439',
        'https://forums.huntedcow.com/index.php?showuser=128578',
        'https://hi-fi-forum.net/profile/987526',
        'https://blogfreely.net/du88win/',
        'https://writeablog.net/du88win/nha-cai-du88',
        'https://zenwriting.net/du88win/nha-cai-du88',
        'https://www.rentalocalfriend.com/en/friends/du88win',
        'https://www.grepper.com/profile/du88win',
        'https://mississaugachinese.ca/home.php?mod=space&uid=1350439',
        'https://www.phraseum.com/user/49410',
        'https://timeoftheworld.date/wiki/User:Du88win',
        'http://www.hoektronics.com/author/du88win/',
        'https://www.myxwiki.org/xwiki/bin/view/XWiki/du88win',
        'https://www.deafvideo.tv/vlogger/du88win',
        'https://gamebanana.com/members/3980503',
        'https://phijkchu.com/a/du88win/video-channels',
        'https://gegenstimme.tv/a/du88win/video-channels',
        'https://www.haikudeck.com/presentations/du88win',
        'https://kurs.com.ua/profile/71701-du88win/?tab=field_core_pfield_11',
        'https://www.skool.com/@du-win-7152',
        'https://cuchichi.es/author/du88win/',
        'https://photosynthesis.bg/user/art/du88win.html',
        'https://forum-mechanika.pl/members/du88win.301053/#about',
        'https://user.qoo-app.com/en/98775642',
        'https://eyecandid.io/user/du88win-10089136/gallery',
        'https://respostas.guiadopc.com.br/user/du88win',
        'https://my.clickthecity.com/du88win',
        'https://mez.ink/du88win',
        'https://forums.starcontrol.com/user/7399635',
        'https://war-lords.net/forum/user-37779.html',
        'https://www.wowonder.xyz/du88win',
        'https://www.sciencebee.com.bd/qna/user/du88win',
        'https://community.jamf.com/t5/user/viewprofilepage/user-id/166069',
        'https://protistologists.org/forums/users/du88win/',
        'https://vozer.net/members/du88win.17745/',
        'https://bulios.com/@du88win',
        'https://forum.dboglobal.to/wsc/index.php?user/82864-du88win/#about',
        'https://www.capakaspa.info/forums-echecs/utilisateurs/du88win/',
        'https://app.impactplus.com/users/du88win',
        'https://app.hellothematic.com/creator/profile/914515',
        'https://www.brownbook.net/business/53271344/du88win/',
        'https://1businessworld.com/pro/du88win/',
        'https://www.servinord.com/phpBB2/profile.php?mode=viewprofile&u=662523',
        'https://community.goldposter.com/members/du88win/profile/',
        'https://www.robot-forum.com/user/183751-du88win/',
        'https://tatoeba.org/vi/user/profile/du88win',
        'https://www.gamblingtherapy.org/forum/users/du88win/',
        'https://git.fuwafuwa.moe/du88win',
        'https://commu.nosv.org/p/du88win/',
        'https://shareyoursocial.com/du88win',
        'https://codeberg.org/du88win',
        'https://www.remotecentral.com/cgi-bin/forums/members/viewprofile.cgi?8dzbh5',
        'https://www.lingvolive.com/en-us/profile/ca57522c-486c-4e85-a227-250d52e8bff7/translations',
        'https://du88win.mypixieset.com/',
        'https://du88win.mystrikingly.com/',
        'https://du88win.blogspot.com/',
        'https://www.gta5-mods.com/users/du88win',
        'https://padlet.com/uyendao106/du88win-cwpn0ov9asvw2cun',
        'https://www.reverbnation.com/artist/du88win',
        'https://pbase.com/du88win',
        'http://atlantabackflowtesting.com/UserProfile/tabid/43/userId/872862/Default.aspx',
        'https://community.amd.com/t5/user/viewprofilepage/user-id/450335',
        'https://du88win.simplecast.com/',
        'https://my.archdaily.com/us/@du88win',
        'https://trakteer.id/du88win',
        'https://www.blockdit.com/du88win',
        'https://www.niftygateway.com/@du88win/',
        'https://varecha.pravda.sk/profil/du88win/o-mne/',
        'https://app.scholasticahq.com/scholars/356375-du88-win',
        'https://newspicks.com/user/10835751/',
        'https://www.guildquality.com/crew/post/32097',
        'https://comiko.net/u/2307009-du88win',
        'https://pinshape.com/users/6113008-du88win#designs-tab-open',
        'https://wikizilla.org/wiki/User:Du88win',
        'https://www.fitday.com/fitness/forums/members/du88win.html',
        'https://www.dohtheme.com/community/members/du8win.89625/#about',
        'https://www.huntingnet.com/forum/members/du88win.html',
        'https://bikeindex.org/users/du88win',
        'https://www.bestadsontv.com/profile/495300/Du88-Win',
        'https://www.rctech.net/forum/members/du88win-419996.html',
        'https://www.jqwidgets.com/community/users/du88win/',
        'https://inkbunny.net/du88win',
        'https://www.balatarin.com/users/du88win',
        'https://www.equinenow.com/farm/du88win.htm',
        'https://caramellaapp.com/du88win/XnQryQC1k/du88win',
        'https://www.dnnsoftware.com/activity-feed/my-profile/userid/3223981',
        'https://s.id/du88win',
        'https://pad.fs.lmu.de/s/BBIhFZN3q',
        'https://www.anime-sharing.com/members/du88win.397184/#about',
        'https://7sky.life/members/du88win/',
        'http://galeria.farvista.net/member.php?action=showprofile&user_id=45185',
        'https://permacultureglobal.org/users/78093-du88-win',
        'https://circleten.org/a/325702',
        'https://sorucevap.sihirlielma.com/user/du88win',
        'https://cadillacsociety.com/users/du88win/',
        'https://metaldevastationradio.com/du88win',
        'https://illust.daysneo.com/illustrator/du88win/',
        'https://www.zubersoft.com/mobilesheets/forum/user-52336.html',
        'https://articlement.com/author/du88win-523747/',
        'https://www.wikiwicca.com/forums/users/du88win/',
        'https://www.recepti.com/profile/view/111738',
        'https://doselect.com/@d4ad3e32087229429be143dee',
        'https://forum.lexulous.com/user/du88win',
        'https://b.io/du88win',
        'https://www.makululinux.com/wp/forums/users/du88win/',
        'https://rebrickable.com/users/du88win/',
        'https://www.discogs.com/fr/user/du88win',
        'https://wowgilden.net/profile_279524.html',
        'https://www.bloggportalen.se/BlogPortal/view/ReportBlog?id=222379',
        'https://git.cryto.net/du88win',
        'https://www.ozbargain.com.au/user/529627',
        'https://axe.rs/forum/members/du88win.13386642/#about',
        'https://www.halaltrip.com/user/profile/179925/du88win/',
        'https://forum.gekko.wizb.it/user-28108.html',
        'https://granotas.net/user/du88win',
        'https://fulhamusa.com/members/du88win.4322/',
        'http://forum.modulebazaar.com/forums/user/du88win/',
        'https://forum.musicalpraxis.gr/forum/profile/du88win/',
        'https://nawaksara.id/forum/profile/du88win/',
        'https://kerbalx.com/du88win',
        'https://forum.dfwmas.org/index.php?members/du88win.110768/#about',
        'https://fabble.cc/du88win',
        'https://gwarminska.pl/author/du88win/',
        'https://du88win.hashnode.dev/nha-cai-du88',
        'https://wayranks.com/author/du88win-759402/',
        'https://du88win.muragon.com/entry/1.html',
        'https://onelifecollective.com/du88win',
        'https://oredigger.net/author/du88win/',
        'https://slides.com/du88win',
        'https://www.guiafacillagos.com.br/author/du88win/',
        'https://es.stylevore.com/user/du88win',
        'https://dapp.orvium.io/profile/du88-win',
        'https://konsumencerdas.id/forum/user/du88win',
        'https://android-help.ru/forum/user/9847-du88win/',
        'https://forum.instube.com/d/177427-du88win',
        'https://code.snapstream.com/index.php?title=User:Du88win',
        'https://teamcnut.com/du88win/',
        'https://chodaumoi247.com/members/du88win.15445/#about',
        'https://slatestarcodex.com/author/du88win/',
        'https://acomics.ru/-du88win',
        'https://www.canadavideocompanies.ca/forums/users/du88win/',
        'https://postgresconf.org/users/du88-win',
        'https://www.planet-casio.com/Fr/compte/voir_profil.php?membre=du88win',
        'https://www.zeldaspeedruns.com/profiles/du88win',
        'http://www.pueblosecreto.com/Net/profile/view_profile.aspx?MemberId=1378263',
        'https://abp.io/community/members/du88win',
        'https://library.zortrax.com/members/du88win/',
        'https://open.firstory.me/user/du88win/about',
        'https://3dtoday.ru/blogs/du88win',
        'https://espritgames.com/members/45131127/',
        'https://www.40billion.com/profile/595368183',
        'https://www.dibiz.com/uyendao106',
        'https://stylowi.pl/59691054',
        'https://dongnairaovat.com/members/du88win.26014.html',
        'https://chimcanhviet.vn/forum/members/du88win.187131/#about',
        'https://digiex.net/members/du88win.116521/',
        'https://www.5giay.vn/members/du88win.101993093/',
        'https://yoo.rs/@du88win',
        'https://lit.link/du88win',
        'https://dc-kd.ru/user/du88win/',
        'https://du88win.godaddysites.com/',
        'https://eo-college.org/members/du88win/',
        'https://startupxplore.com/en/startups/du88win',
        'https://it.picmix.com/profile/du88win',
        'https://coronavirus.tghn.org/community/members/1011331/',
        'https://jali.me/du88win',
        'https://pad.lescommuns.org/s/de3-Mou1l',
        'https://www.fimfiction.net/user/816718/du88win',
        'https://developers.oxwall.com/user/du88win',
        'https://www.angrybirdsnest.com/members/du88win/profile/',
        'https://faceparty.com/du88win',
        'https://www.jigsawplanet.com/du88win',
        'https://global14.com/members/du88win/profile/',
        'https://colab.research.google.com/drive/1wzPtFas86lX365QQLJeO2w105XuB1Gmu?usp=sharing',
        'https://heavenarticle.com/author/du88win-1444908/',
        'https://wallhaven.cc/user/du88win',
        'https://makeagif.com/user/du88win?ref=JmUkpI',
        'https://cloudim.copiny.com/question/details/id/959303',
        'https://advego.com/profile/du88win/',
        'https://diit.cz/profil/ar4caszseu',
        'https://tooter.in/du88win',
        'https://expatguidekorea.com/profile/du88win/',
        'https://timdaily.vn/members/du88win.92977/#about',
        'https://wiki.sports-5.ch/index.php?title=Utilisateur:Du88win',
        'https://forum.aceinna.com/user/du88win',
        'http://newdigital-world.com/members/du88win.html',
        'https://www.udrpsearch.com/user/du88win',
        'https://akniga.org/profile/700923-du88win/',
        'https://akwatik.com/post/4654',
        'https://codex.core77.com/users/du88win',
        'https://3dexport.com/du88win',
        'https://www.investagrams.com/Profile/du88win',
        'https://www.atozed.com/forums/user-17141.html',
        'https://shenasname.ir/ask/user/du88win',
        'https://odesli.co/pqcf6rvkjqrcf',
        'https://community.wibutler.com/user/du88win',
        'https://blogs.lowcostavia.com.ua/members/du88win/profile/',
        'https://community.enrgtech.co.uk/forums/users/du88win/',
        'https://polars.pourpres.net/user-8524',
        'https://www.max2play.com/en/forums/users/du88win/',
        'https://oyaschool.com/users/du88win/',
        'https://www.kuhustle.com/@du88win',
        'https://vc.ru/u/4199435-du88win',
        'https://www.horseracingnation.com/user/du88win',
        'https://monocil.jp/users/du88win/',
        'https://refresher.cz/profil/du88win',
        'https://samplefocus.com/users/du88win',
        'https://du88win.gumroad.com/',
        'https://perftile.art/users/du88win',
        'http://www.gamingtop100.net/server/26361/du88win',
        'https://construim.fedaia.org/profiles/du88win/activity',
        'https://boredofstudies.org/members/du88win.1611410919/#about',
        'https://biomolecula.ru/authors/36547',
        'https://jobs.suncommunitynews.com/profiles/5610181-du88-win',
        'https://protocol.ooo/ja/users/du88win',
        'https://3dlancer.net/profile/u1063323',
        'https://www.neizvestniy-geniy.ru/users/129132.html',
        'https://www.eso-database.com/en/user/du88win',
        'https://geniidata.com/user/du88win',
        'https://linknox.com/du88win',
        'https://du88win.livepositively.com/',
        'https://ask.embedded-wizard.de/user/du88win',
        'https://www.sunlitcentrekenya.co.ke/author/du88win/',
        'https://40th.jiuzhai.com/space-uid-2795813.html',
        'https://manacube.com/members/du88win.238894/#about',
        'https://technosmarter.com/qa/user/du88win',
        'https://igli.me/du88win',
        'https://dtf.ru/u/2122030-du88-win',
        'https://linkstack.lgbt/@du88win',
        'https://www.aparat.com/u_21451536/about',
        'https://redfernelectronics.co.uk/forums/users/du88win/',
        'https://www.question-ksa.com/user/du88win',
        'https://www.retecool.com/author/du88win/',
        'https://digiphoto.techbang.com/users/du88win',
        'https://www.fintact.io/user/du88win',
        'https://www.nu6i-bg-net.com/user/du88win/',
        'https://www.openlb.net/forum/users/du88win/',
        'https://aiforkids.in/qa/user/du88win',
        'https://forum.citadel.one/user/du88win',
        'https://rfc.stitcher.io/profile/du88win',
        'https://djrankings.org/profile-du88win',
        'https://xiaopan.co/forums/members/du88win.174109/',
        'https://truckymods.io/user/288656',
        'https://codeandsupply.co/users/ng_uVR6yLI6iHA',
        'https://contest.embarcados.com.br/membro/du88-win/',
        'https://olderworkers.com.au/author/uyendao106gmail-com/',
        'https://lessons.drawspace.com/post/815031/du88win',
        'https://sarah30.com/users/du88win',
        'https://egl.circlly.com/users/du88win',
        'https://killtv.me/user/du88win/',
        'https://du88win.onlc.eu/',
        'https://www.autickar.cz/user/profil/8560/',
        'https://forum.skullgirlsmobile.com/members/du88win.64436/#about',
        'https://www.proko.com/@du88win/activity',
        'https://buckeyescoop.com/community/members/du88win.21040/#about',
        'https://www.access-programmers.co.uk/forums/members/du88win.172000/#about',
        'https://travel98.com/member/143634',
        'https://www.moshpyt.com/user/du88win',
        'https://www.plotterusati.it/user/du88win',
        'https://confengine.com/user/du88win',
        'https://tealfeed.com/du88win',
        'https://forum.fluig.com/users/40876/du88win',
        'https://promosimple.com/ps/30227/du88win',
        'https://du88win.onlc.be/',
        'https://www.behance.net/du88win',
        'https://www.stem.org.uk/user/1413488',
        'https://www.mycast.io/profiles/304759/username/du88win',
        'https://support.smartplugins.info/forums/users/du88win/',
        'https://hyvebook.com/du88win',
        'https://3ddd.ru/users/du88win',
        'http://du88win.idea.informer.com/',
        'https://tabbles.net/users/du88-win/',
        'https://www.pub100s.com/members/du88win/profile/',
        'https://www.2000fun.com/home-space-uid-4822520-do-profile.html',
        'https://noti.st/du88win',
        'https://wykop.pl/ludzie/du88win',
        'https://pastebin.com/u/du88win',
        'https://forum.ljubavni-oglasnik.net/members/du88win.53759/#about',
        'https://soucial.net/du88win',
        'https://du88win.bloguetechno.com/du88win-66530966',
        'https://airsoftc3.com/user/108932/du88-win?p=activity',
        'https://diendannhansu.com/members/du88win.80240/#about',
        'https://webspeed.intensys.pl/wyniki/206878/',
        'https://701908.8b.io/',
        'https://twain.org/forums/users/du88win/',
        'https://siapabilang.com/Du88-Win/',
        'http://kedcorp.org/UserProfile/tabid/42/userId/85895/Default.aspx',
        'https://ferrariformula1.hu/community/profile/du88win/',
        'https://defolio.com/du88-win',
        'https://influence.co/du88win',
        'https://www.malikmobile.com/du88win',
        'https://hackaday.io/du88win',
        'https://www.designspiration.com/du88win/',
        'https://www.webwiki.com/du88.win',
        'https://www.mountainproject.com/user/201953843/du88-win',
        'https://allmyfaves.com/du88win',
        'https://jobs.insolidarityproject.com/profiles/5610821-du88-win',
        'https://www.multichain.com/qa/user/du88win',
        'https://public.tableau.com/app/profile/du88.win/vizzes',
        'https://jobs.windomnews.com/profiles/5610818-du88-win',
        'https://hiqy.in/du88win',
        'https://band.us/band/96820645/intro',
        'https://issuu.com/du88win',
        'https://roomstyler.com/users/du88win',
        'https://doodleordie.com/profile/du88win',
        'https://heylink.me/du88win/',
        'https://qooh.me/du88win',
        'https://www.producthunt.com/@du88win',
        'https://www.openstreetmap.org/user/du88win',
        'https://b.hatena.ne.jp/du88win/bookmark',
        'https://link.space/@du88win',
        'https://coub.com/du88.win',
        'https://justpaste.it/u/du88win',
        'https://fliphtml5.com/homepage/lltns/',
        'https://www.diigo.com/profile/du88win',
        'https://www.metooo.io/u/du88win',
        'https://www.ohay.tv/profile/du88win',
        'https://allmy.bio/du88win',
        'https://www.webwiki.nl/du88.win',
        'https://linkmix.co/31003353',
        'https://www.checkli.com/du88win',
        'https://zzb.bz/du88win',
        'https://www.nintendo-master.com/profil/du88win',
        'https://www.heroesfire.com/profile/du88win/bio',
        'https://www.blackhatprotools.info/member.php?206837-du88win',
        'https://shapshare.com/du88win',
        'https://www.metaculus.com/accounts/profile/228444/',
        'https://apk.tw/space-uid-6739571.html',
        'https://wibki.com/du88win',
        'https://rotorbuilds.com/profile/75553/',
        'https://filesharingtalk.com/members/605210-du88win',
        'http://compcar.ru/forum/member.php?u=135889',
        'https://advpr.net/du88win',
        'http://planforexams.com/q2a/user/du88win',
        'https://www.buzzbii.com/du88win',
        'https://qna.habr.com/user/du88win',
        'https://forum.profa.ne/user/du88win',
        'https://fewpal.com/du88win',
        'https://jobs.njota.org/profiles/5611154-du88-win',
        'https://www.decidim.barcelona/profiles/du88win/activity',
        'https://www.iconfinder.com/user/du88win',
        'https://decidim.rezero.cat/profiles/du88win/activity',
        'https://web.ggather.com/du88win',
        'https://anyflip.com/homepage/xdrgo',
        'https://www.iglinks.io/uyendao106-pst',
        'https://vocal.media/authors/du88win',
        'https://www.adsoftheworld.com/users/7533b76c-c3d0-47b9-833f-f8b3d3666dce',
        'https://triberr.com/du88win',
        'https://www.pageorama.com/?p=du88win',
        'https://motion-gallery.net/users/670473',
        'https://notionpress.com/author/1115723',
        'https://experiment.com/users/du88win',
        'https://www.giveawayoftheday.com/forums/profile/240665',
        'https://www.aicrowd.com/participants/du88win/',
        'https://hanson.net/users/du88win',
        'https://ourairports.com/members/du88win/',
        'https://sovren.media/u/du88win/',
        'https://aboutme.style/du88win',
        'https://hcgdietinfo.com/hcgdietforums/members/du88win/',
        'http://freestyler.ws/user/498602/du88win',
        'https://market360.vn/page/du88win',
        'https://potofu.me/du88win',
        'https://www.facer.io/u/du88win',
        'https://barcelonadema-participa.cat/profiles/du88win/activity',
        'https://decidim.pontault-combault.fr/profiles/du88win/activity',
        'https://participa.tarragona.cat/profiles/du88win/activity',
        'https://dialog.eslov.se/profiles/du88win/activity?locale=en',
        'https://decidim.opcions.coop/profiles/du88win/activity',
        'https://co-roma.openheritage.eu/profiles/du88win/activity',
        'https://pins.schuttrange.lu/profiles/du88win/activity',
        'http://thietbidien.sangnhuong.com/member.php?u=102007',
        'https://groups.google.com/g/du88win/c/gc8q1vEJTAg',
        'http://molbiol.ru/forums/index.php?showuser=1403014',
        'https://wto.to/u/2307009-du88win',
        'https://www.projectnoah.org/users/du88win',
        'https://www.metooo.it/u/du88win',
        'https://careers.gita.org/profiles/5611508-du88-win',
        'https://expathealthseoul.com/profile/du88win/',
        'https://www.mazafakas.com/user/profile/5134166',
        'https://www.ask-people.net/user/du88win',
        'https://iszene.com/user-248069.html',
        'https://mstdn.business/@du88win',
        'https://qa.laodongzu.com/?qa=user/du88win',
        'https://www.wvhired.com/profiles/5611497-du88-win',
        'https://www.clickasnap.com/profile/du88win',
        'https://www.ixawiki.com/link.php?url=https://du88.win/',
        'https://www.pdc.edu/?URL=https://du88.win/',
        'https://du88win.livejournal.com/381.html?newpost=1',
        'https://raovat.nhadat.vn/members/du88win-145280.html',
        'https://gitlab.aicrowd.com/du88win',
        'https://www.metooo.es/u/du88win',
        'https://macro.market/company/du88win',
        'https://expressafrica.net/du88win',
        'https://writexo.com/share/72f2kg58',
        'https://www.notebook.ai/@du88win',
        'https://www.plurk.com/du88win',
        'https://www.hebergementweb.org/members/du88win.709007/',
        'https://www.circleme.com/du88win',
        'https://du88win.notepin.co/',
        'https://thearticlesdirectory.co.uk/members/uyendao106/',
        'https://ai.ceo/du88win',
        'https://ask.mallaky.com/?qa=user/du88win',
        'https://du88win.website3.me/',
        'https://turkish.ava360.com/user/du88win/',
        'https://www.voyage-to.me/du88win',
        'https://soundcloud.com/du88win',
        'https://demo.wowonder.com/du88win',
        'https://wirtube.de/a/du88win/video-channels',
        'https://kowabana.jp/users/135894',
        'https://kansabook.com/du88win',
        'http://uno-en-ligne.com/profile.php?user=380895',
        'https://mangatoto.net/u/2307009-du88win',
        'https://draft.blogger.com/profile/13243732931329192158',
        'https://zbato.com/u/2307009-du88win',
        'https://du88win.amebaownd.com/posts/55863319',
        'https://du88win.themedia.jp/posts/55863320',
        'https://du88win.localinfo.jp/posts/55863321',
        'https://drivehud.com/forums/users/uyendao106/',
        'https://trustgroup.blog/du88win',
        'https://www.muvizu.com/Profile/du88win/Latest/',
        'https://onlineboxing.net/jforum/user/profile/328089.page',
        'https://fileforum.com/profile/du88win',
        'https://telegra.ph/Nha-cai-DU88-11-19',
        'https://wmart.kz/forum/user/196844/',
        'https://developer.tobii.com/community-forums/members/du88win/',
        'https://about.me/du88win',
        'http://gendou.com/user/du88win',
        'https://profile.hatena.ne.jp/du88win/profile',
        'https://tvchrist.ning.com/profile/du88win',
        'https://jsfiddle.net/du88win/75026rdw/',
        'https://www2.teu.ac.jp/iws/elc/pukiwiki/?du88win',
        'http://www.fanart-central.net/user/du88win/profile',
        'https://velopiter.spb.ru/profile/144135-du88win/?tab=field_core_pfield_1',
        'https://jobs251.com/author/du88win/',
        'https://www.betting-forum.com/members/du88win.81102/#about',
        'https://myanimelist.net/profile/du88win',
        'https://allmylinks.com/du88win',
        'https://talk.plesk.com/members/duwin.379087/#about',
        'https://www.smitefire.com/profile/du88win-182302',
        'https://www.fundable.com/du88-win',
        'https://www.maanation.com/du88win',
        'http://cuuho.sangnhuong.com/member.php?u=105376',
        'https://schoolido.lu/user/du88win/',
        'http://gioxach.sangnhuong.com/member.php?u=109020',
        'https://www.itchyforum.com/en/member.php?311578-du88win',
        'https://participa.economiasocialcatalunya.cat/profiles/du88win/activity',
        'https://signes-participatif.fr/profiles/du88win/activity?locale=en',
        'http://delphi.larsbo.org/user/du88win',
        'https://youbiz.com/profile/du88win/',
        'https://bresdel.com/du88win',
        'https://git.project-hobbit.eu/du88win',
        'https://uconnect.ae/du88win',
        'https://my.bio/du88win',
        'https://whatson.plus/du88win',
        'https://batocomic.net/u/2307009-du88win',
        'http://1ctv.cn/home.php?mod=space&uid=2971186',
        'https://batocomic.org/u/2307009-du88win',
        'https://matkafasi.com/user/du88win',
        'https://www.lexaloffle.com/bbs/?uid=112535',
        'https://www.outdoorproject.com/users/du88-win',
        'https://taba.truesnow.jp/palace_trick_wiki/index.php?du88win',
        'https://forum.melanoma.org/user/du88win/profile/',
        'https://www.flyingsolo.com.au/members/du88win/profile/',
        'https://raovat49.com/s/nha-cai-du88-2024-5688268',
        'http://dtan.thaiembassy.de/uncategorized/2562/?mingleforumaction=profile&id=245923',
        'http://www.chenjiagou.net/home.php?mod=space&uid=211102',
        'https://www.ethiovisit.com/myplace/du88win',
        'https://jto.to/u/2307009-du88win',
        'https://m.wibki.com/du88win',
        'https://phileo.me/du88win',
        'https://www.xaphyr.com/du88win',
        'https://v.gd/IEo3zE',
        'https://www.vevioz.com/du88win',
        'https://www.decidimmataro.cat/profiles/du88win/activity',
        'https://battwo.com/u/2307009-du88win',
        'https://social1776.com/du88win',
        'https://www.webwiki.it/du88.win',
        'https://www.webwikis.es/du88.win',
        'https://www.dotafire.com/profile/du88win-137679',
        'https://justnock.com/du88win',
        'https://peatix.com/user/24742346/view',
        'https://bookmeter.com/users/1537450',
        'https://www.mapleprimes.com/users/du88win',
        'https://www.mymeetbook.com/du88win',
        'https://www.socialbookmarkssite.com/bookmark/5718203/du88win/',
        'https://makeprojects.com/profile/du88win',
        'https://lwccareers.lindsey.edu/profiles/5612784-du88-win',
        'https://makersplace.com/uyendao106/about',
        'https://www.free-ebooks.net/profile/1596354/du88-win',
        'https://www.uclgmeets.org/profiles/du88win/activity?locale=fr',
        'https://participacion.cabildofuer.es/profiles/du88win/activity?locale=en',
        'https://engage.eiturbanmobility.eu/profiles/du88win/activity',
        'https://masculinitats.decidim.barcelona/profiles/du88win/activity',
        'https://fairebruxellessamen.be/profiles/du88win/activity',
        'https://hubpages.com/@du88win',
        'https://linktr.ee/du88win',
        'https://sketchfab.com/du88win',
        'https://www.gaiaonline.com/profiles/du88win/46917996/',
        'https://hackmd.io/@du88win/du88win',
        'https://klotzlube.ru/forum/user/289781/',
        'https://vnvista.com/hi/184236',
        'https://www.ilcirotano.it/annunci/author/du88win/',
        'https://www.outlived.co.uk/author/du88win/',
        'https://glamorouslengths.com/author/du88win/',
        'https://www.divephotoguide.com/user/du88win',
        'https://bitspower.com/support/user/du88win',
        'http://jobs.emiogp.com/author/du88win/',
        'https://my.desktopnexus.com/du88win/',
        'http://aldenfamilydentistry.com/UserProfile/tabid/57/userId/964253/Default.aspx',
        'https://www.asklent.com/user/du88win',
        'https://postheaven.net/du88win/nha-cai-du88',
        'https://hangoutshelp.net/user/du88win',
        'https://www.furaffinity.net/user/du88win',
        'https://www.giantbomb.com/profile/du88win/',
        'https://photoclub.canadiangeographic.ca/profile/21427164',
        'https://du88win.shopinfo.jp/posts/55863676',
        'https://du88win.therestaurant.jp/posts/55863675',
        'https://portfolium.com/du88win',
        'https://able2know.org/user/du88win/',
        'https://www.proarti.fr/account/du88win',
        'https://www.naucmese.cz/du88-win',
        'https://www.telix.pl/profile/du88win/',
        'https://hindibookmark.com/story20108341/du88win',
        'https://bookmarkport.com/story20622346/du88win',
        'https://businessbookmark.com/story3884322/du88win',
        'https://dirstop.com/story21201795/du88win',
        'https://designaddict.com/community/profile/du88win/',
        'https://673c84dccc591.site123.me/',
        'https://bootstrapbay.com/user/du88win',
        'https://www.australia-australie.com/membres/du88win/profile/',
        'https://www.chaloke.com/forums/users/du88win/',
        'https://jobs.westerncity.com/profiles/5613019-du88-win',
        'https://www.adsfare.com/du88win',
        'http://www.rohitab.com/discuss/user/2405150-du88win/',
        'https://tudomuaban.com/chi-tiet-rao-vat/2403782/nha-cai-du88-2024.html',
        'https://www.babyweb.cz/uzivatele/du88win',
        'https://www.rehashclothes.com/du88win',
        'https://findaspring.org/members/du88win/',
        'https://vietnam.net.vn/members/du88win.30070/',
        'http://jobboard.piasd.org/author/du88win/',
        'https://kaeuchi.jp/forums/users/du88win/',
        'https://deadreckoninggame.com/index.php/User:Du88win',
        'https://docvino.com/members/du88win/profile/',
        'https://mangatoto.com/u/2307009-du88win',
        'https://anotepad.com/notes/9krd4kga',
        'http://l-avt.ru/support/dialog/?PAGE_NAME=profile_view&UID=83562',
        'https://mecabricks.com/en/user/duwin',
        'https://www.pozible.com/profile/du88win',
        'https://www.exchangle.com/du88win',
        'https://readtoto.org/u/2307009-du88win',
        'https://www.crokes.com/du88win/info/',
        'https://lovelinetapes.com/members/du88win/profile/',
        'https://jii.li/du88win',
        'https://readtoto.com/u/2307009-du88win',
        'https://php.ru/forum/members/du88win.148068/',
        'https://participez.perigueux.fr/profiles/du88win/activity',
        'https://du88win.threadless.com/about',
        'https://getinkspired.com/en/u/du88win/',
        'https://brightcominvestors.com/forums/users/du88win/',
        'https://decidim.tjussana.cat/profiles/du88win/activity',
        'https://nguoiquangbinh.net/forum/diendan/member.php?u=152170',
        'https://fic.decidim.barcelona/profiles/du88win/activity',
        'https://xbato.net/u/2307009-du88win',
        'https://www.rcuniverse.com/forum/members/du88win.html',
        'https://penposh.com/du88win',
        'https://www.abnewswire.com/companyname/du88win.vn_146550.html',
        'https://www.bookemon.com/member-home/du88win/1079530',
        'https://devdojo.com/du88win',
        'https://subscribe.ru/author/31653253',
        'https://videos.muvizu.com/Profile/du88win/Latest/',
        'https://opentutorials.org/profile/192267',
        'https://community.fabric.microsoft.com/t5/user/viewprofilepage/user-id/855196',
        'https://in.enrollbusiness.com/BusinessProfile/6944604/du88win',
        'https://creativemornings.com/individuals/du88win',
        'https://www.freewebmarks.com/story/nh-ci-du88-2',
        'https://www.themplsegotist.com/members/du88win/',
        'https://www.sythe.org/members/du88win.1819739/',
        'https://www.portalnet.cl/usuarios/du88win.1121215/',
        'http://www.haxorware.com/forums/member.php?action=profile&uid=308857',
        'https://aspiriamc.com/members/du88win.49866/#about',
        'https://www.growkudos.com/profile/du88_win',
        'https://fto.to/u/2307009-du88win',
        'https://autismuk.com/autism-forum/users/du88win/',
        'https://talk.tacklewarehouse.com/index.php?members/du88win.35045/#about',
        'https://blacksnetwork.net/du88win',
        'https://www.diggerslist.com/du88win/about',
        'https://qeiark-triity-mccaicy.yolasite.com/',
        'https://us.enrollbusiness.com/BusinessProfile/6944604/du88win',
        'https://techplanet.today/member/du88-win',
        'https://divinedirectory.com/author/du88win-15287/',
        'https://zealy.io/c/du88win',
        'https://hu.gravatar.com/du88win',
        'https://voz.vn/u/du88win.2064494/#about',
        'https://myurls.bio/du88win',
        'https://www.iniuria.us/forum/member.php?486913-du88win',
        'https://community.concretecms.com/members/profile/view/366171',
        'https://www.kuettu.com/du88win',
        'https://www.wikidot.com/user:info/du88win',
        'https://www.beatstars.com/du88win',
        'https://www.medflyfish.com/index.php?action=profile;u=5774793',
        'https://e27.co/user/du88.win/',
        'https://facekindle.com/du88win',
        'https://www.xc40forum.com/members/du88win.19712/#about',
        'https://copynotes.be/shift4me/forum/user-6652.html',
        'https://rant.li/du88win/nha-cai-du88',
        'http://suhinfo.ru/index.php/%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA:Du88win',
        'https://toptohigh.com/author/du88win-342173/',
        'https://www.bmw-sg.com/forums/members/du88win.98106/#about',
        'https://wikifab.org/wiki/Utilisateur:Du88win',
        'https://git.hemio.de/du88win',
        'https://www.bikemap.net/de/u/du88win/routes/created/',
        'https://linkr.bio/du88win',
        'https://freelance.habr.com/freelancers/du88win',
        'https://backabuddy.co.za/campaign/du88-win',
        'https://pumpyoursound.com/u/user/1456170',
        'https://render.ru/ru/artist/269299/about',
        'https://forum.rodina-rp.com/members/294018/#about',
        'https://crowdsourcer.io/profile/ZHKzqQHL',
        'https://cinderella.pro/user/190308/du88win/#preferences',
        'https://userstyles.world/user/du88win',
        'https://zimexapp.co.zw/du88win',
        'https://www.blackhatworld.com/members/du88win.2048613/#about',
        'http://caphe.sangnhuong.com/member.php?u=111422',
        'http://matdo.sangnhuong.com/member.php?u=107052',
        'https://www.webwiki.fr/du88.win',
        'https://www.webwiki.at/du88.win',
        'https://tapchivatuyentap.tlu.edu.vn/Activity-Feed/My-Profile/UserId/65765',
        'https://alphacs.ro/member.php?83721-du88win',
        'https://memes.tw/user/343722',
        'https://madripedia.wikis.cc/wiki/Usuario:Du88win',
        'https://www.ultimate-guitar.com/u/uyendao106',
        'https://www.physicsoverflow.org/user/du88win',
        'http://prsync.com/duwin/',
        'https://www.algebra.com/tutors/aboutme.mpl?userid=du88win',
        'https://pc.poradna.net/users/855403413-du88win',
        'http://www.stes.tyc.edu.tw/xoops/modules/profile/userinfo.php?uid=2474115',
        'https://forum.issabel.org/u/du88win',
        'https://we-xpats.com/vi/member/13943/',
        'https://church.ibible.hk/du88win',
        'https://events.opensuse.org/users/648595',
        'http://du88win.geoblog.pl/',
        'https://netgork.com/du88win',
        'https://www.freelistingusa.com/listings/du88win',
        'https://personaljournal.ca/du88win/du88-la-nha-cai-ca-cuoc-bong-da-the-thao-live-casino-lo-de-game-bai-doi',
        'https://www.coolerads.com/consumers/profile/244007',
        'https://alphacommunity.in/member/alpha-342123/',
        'https://uiverse.io/profile/du88win',
        'https://talkmarkets.com/member/du88win',
        'http://rias.ivanovo.ru/cgi-bin/mwf/user_info.pl?uid=35029',
        'https://forum.lyrsense.com/member.php?u=48481',
        'https://amaz0ns.com/forums/users/du88win',
        'http://forum.vodobox.com/profile.php?id=9253',
        'https://haveagood.holiday/users/376011',
        'https://www.penmai.com/community/members/du88win.421222/#about',
        'https://muabanhaiduong.com/members/du88win.14905/#about',
        'https://www.sabre.org.au/forum/users/du88win/',
        'https://www.swtestingjobs.com/author/du88win/',
        'https://foro.noticias3d.com/vbulletin/member.php?u=288095',
        'https://catchmentbasedapproach.org/forums/users/du88win/',
        'https://du88win.bravesites.com/',
        'https://du88win.gitbook.io/du88win/~/changes/AEiGc3rmpp8LQabkIJhh?r=LjOpGKY3LEQriUTWSVix',
        'https://vietfones.vn/forum/members/du88win.261711/',
        'https://racetime.gg/user/R6ym83mbQjoPd1Xr/du88win'
      ]
    }
  )
]

function generateHash(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let hash = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    hash += characters[randomIndex]
  }

  return hash
}
