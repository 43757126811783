import { useAppStore } from '~/store/app'
import { schemaSeoConfig } from '~/constants/seo'

export default defineNuxtPlugin(({ $domainUrl, $config }) => {
  const store = useAppStore()
  // add schema data seo
  useHead({
    script: schemaSeoConfig($domainUrl as string, $config.public.STATIC_BASE_URL)
      .map((schema) => {
        const seoImg = store.seoData.find((seo: any) => seo.alias === '/')?.seo_img
        const obj = JSON.parse(schema)
        if (seoImg) {
          obj.image = seoImg
        }
        return JSON.stringify(obj)
      })
      .map((schema) => ({
        type: 'application/ld+json',
        innerHTML: schema
      }))
  })
})
