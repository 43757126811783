import { ERROR } from '~/constants/common'
export default defineNuxtPlugin((nuxtApp) => {
  const { $axios, $ua, ssrContext, $config } = nuxtApp as Record<string, any>
  if ($axios && $ua) {
    $axios.defaults.withCredentials = true
    $axios.setHeader('os', $ua.os())
    $axios.setHeader('device', $ua.deviceType())
    $axios.setHeader('browser', $ua.browser())
  }
  const headers = useRequestHeaders()

  const promotionBaseURL = ssrContext?.runtimeConfig?.API_PROMOTION_BASE_URL
  const defaultBaseURL = ssrContext?.runtimeConfig?.API_BASE_URL

  if (ssrContext) {
    $axios.setBaseURL(defaultBaseURL)
  } else {
    $axios.setBaseURL(window.location.origin)
  }

  $axios.onRequest((config: any) => {
    if (ssrContext) {
      const cookies = (config.headers.cookie?.split(';') ?? []).map((cookie: string) => cookie.trim())
      cookies?.push?.(`brand_code=${ssrContext?.runtimeConfig?.BRAND_CODE}`)
      cookies?.push?.(`host=${headers.host}`)

      cookies?.push?.(`device=${$ua.isFromPc() ? 'desktop' : 'mobile'}`)
      config.headers.cookie = cookies.join('; ')
    }
    if (config.url?.startsWith('/api-promotion/')) {
      config.baseURL = promotionBaseURL
    } else if (config.url?.startsWith('/api/')) {
      config.baseURL = defaultBaseURL
    }
    return config
  })

  $axios.interceptors.response.use(function (response: { config: { headers: any; url: any } }) {
    // Just log on server
    if (ssrContext) {
      const headers = response.config.headers

      const requestInfor = {
        IP: headers['x-forwarded-for'] || 'IP cannot detected',
        country: headers['cf-ipcountry'] || 'country cannot detected',
        os: headers.os,
        device: headers.device,
        browser: headers.browser
      }

      console.log(`${ssrContext?.runtimeConfig?.public?.BRANCH_NAME} ${ssrContext?.runtimeConfig?.public?.BRANCH_NAME} ${response.config.url} - ${requestInfor.country} - ${requestInfor.IP} - ${requestInfor.device} - ${requestInfor.os} - ${requestInfor.browser}`)
    }
    return response
  }, function (error: { config: { bypassErrorToast: any }; response: { data: { message: any } } }) {
    if (!ssrContext) {
      const nuxtApp = useNuxtApp()
      const code = parseInt(error?.response?.status ?? 0)
      const requestUrl = error?.config?.url || ''
      const allowShow401MsgApis = ['/login', '/register', '/logout']
      const isBlockShow401Msg = code === 401 && allowShow401MsgApis.some((api) => requestUrl.includes(api))
      if (!error?.config.bypassErrorToast && (isBlockShow401Msg || code !== 401)) {
        nuxtApp.$alertMsg('error', error?.response?.data?.message || ERROR)
      }
    }
    throw error
  })
})
