<template>
  <img
    v-if="!errorImg"
    v-bind="$attrs"
    :id="idImg"
    :src="fullSrc()"
    :alt="dataAlt"
    :width="width"
    :height="height"
    :style="{ borderRadius: borderRadius && `${borderRadius}px` }"
    @error="showAltImage"
  />
</template>

<script setup lang="ts">
const { $config } = useNuxtApp()

const props = defineProps({
  showDefault: {
    type: Boolean,
    default: false
  },
  width: {
    type: String,
    default: undefined
  },
  height: {
    type: String,
    default: undefined
  },
  borderRadius: {
    type: String,
    default: undefined
  },
  lazy: {
    type: Boolean,
    default: false
  },
  waitLazy: {
    type: Boolean,
    default: false
  },
  endLazy: {
    type: Boolean,
    default: false
  },
  src: {
    type: String,
    default: ''
  },
  relativeSrc: {
    type: String,
    default: ''
  },
  alt: {
    type: String,
    default: ''
  },
  defaultSrc: {
    type: String,
    default: ''
  }
})
const isLoaded = ref(!props.lazy)
const errorImg = ref(false)
const fullSrc = () => {
  return props.relativeSrc !== ''
    ? `${props.relativeSrc}?v=${$config.public.APP_VERSION}`
    : (!/^(\s?)http(s?):\/\//.test(props.src) && !/^\/\//.test(props.src)
        ? `${new URL(props.src, $config.public.STATIC_BASE_URL).toString()}?v=${$config.public.APP_VERSION}`
        : props.src)
}
const showAltImage = (event: Event) => {
  if (props.showDefault) {
    errorImg.value = true
  }
  const target = event.target as HTMLImageElement

  const fallbackSrc = props.defaultSrc || '/assets/brand/logo.webp'
  const absoluteFallbackSrc = props.defaultSrc ? new URL(fallbackSrc, $config.public.STATIC_BASE_URL).toString() : fallbackSrc

  if (target.src !== absoluteFallbackSrc) {
    target.src = fallbackSrc
  }
}

const dataAlt = computed(() => {
  return props.alt ? `${props.alt} ${$config.public.BRANCH_NAME}` : $config.public.BRANCH_NAME
})

const idImg = computed(() => {
  const formatId = (str: string) => {
    return str.toLowerCase().replace(/\s+/g, '-') // Replace spaces with hyphens and convert to lowercase
  }
  return props.alt ? `${formatId(props.alt)}-image` : $config.public.BRANCH_NAME
})

const route = useRoute()
watch(route, () => {
  errorImg.value = false
})
watch(
  () => props.endLazy,
  (newValue) => {
    if (props.waitLazy) {
      setTimeout(() => {
        isLoaded.value = newValue
      }, 100)
    }
  }
)
onMounted(() => {
  if (!props.src) {
    return
  }

  if (!props.lazy) {
    isLoaded.value = true
  }

  const img = new Image()
  img.src = fullSrc()
  img.onload = (evt) => {
    setTimeout(() => {
      isLoaded.value = true
    }, 100)
  }
})
</script>
<style lang="scss" scoped src="~/public/assets/scss/components/common/base-img.scss"></style>
