export default defineNuxtRouteMiddleware((to, from) => {
  const redirects = {
    '/livecasino/all': '/livecasino',
    '/livecasino?partner=all': '/livecasino',
    '/game/table-game': '/game/table-games'
  }

  // Check for the double slashes in the URL
  if (to.fullPath.includes('//')) {
    return navigateTo('/')
  }
  if (redirects[to.fullPath]) {
    return navigateTo(redirects[to.fullPath])
  }
})
