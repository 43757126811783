import type { Router, RouteLocationNormalized } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  ;(nuxtApp.$router as Router).options.scrollBehavior = (to, from) => {
    if (to.hash) {
      return {
        el: to.hash
      }
    }

    if (disableScrollOnPopupOpen(to, from)) {
      return { x: 0, y: 0 }
    }

    return { top: 0 }
  }

  useNuxtApp().hook('page:finish', () => {
    setTimeout(() => window.scrollTo(0, -160), 0)
  })
})

const disableScrollOnPopupOpen = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (to.query.popup || from.query.popup || to.query.search === '' || to.query.search !== '') {
    return true
  }
  return false
}
