import * as woothee from 'woothee'

const ANDROID_MOBILE: RegExp = /Android.+Mobi(le)?/

export class UA {
  private _ua: string
  private _parsed: any

  constructor(ua: string) {
    this._ua = ua
    this._parsed = woothee.parse(ua)
  }

  public original(): string {
    return this._ua
  }

  public deviceType(): string {
    return this._parsed.category
  }

  public os(): string {
    return this._parsed.os
  }

  public osVersion(): string {
    return this._parsed.os_version
  }

  public browser(): string {
    return this._parsed.name
  }

  public browserVersion(): string {
    return this._parsed.version
  }

  public browserVendor(): string {
    return this._parsed.vendor
  }

  // iOS-related checks
  public isFromIphone(): boolean {
    return this.os() === 'iPhone'
  }

  public isFromIpad(): boolean {
    return this.os() === 'iPad'
  }

  public isFromIpod(): boolean {
    return this.os() === 'iPod'
  }

  public isFromIos(): boolean {
    return ['iPhone', 'iPad', 'iPod', 'iOS'].includes(this.os())
  }

  // Android-related checks
  public isFromAndroidMobile(): boolean {
    return this.os() === 'Android' && ANDROID_MOBILE.test(this._ua)
  }

  public isFromAndroidTablet(): boolean {
    return this.os() === 'Android' && !ANDROID_MOBILE.test(this._ua)
  }

  public isFromAndroidOs(): boolean {
    return this.os() === 'Android'
  }

  // Other OS checks
  public isFromWindowsPhone(): boolean {
    return this.os() === 'Windows Phone OS'
  }

  // Device type checks
  public isFromPc(): boolean {
    return this.deviceType() === 'pc'
  }

  public isFromSmartphone(): boolean {
    return this.deviceType() === 'smartphone' && !this.isFromTablet()
  }

  public isFromMobilephone(): boolean {
    return this.deviceType() === 'mobilephone'
  }

  public isFromAppliance(): boolean {
    return this.deviceType() === 'appliance'
  }

  public isFromCrawler(): boolean {
    return this.deviceType() === 'crawler'
  }

  public isFromTablet(): boolean {
    return this.isFromIpad() || this.isFromAndroidTablet()
  }

  public getDevice(): string {
    return this._parsed.category === 'pc' ? 'desktop' : 'mobile'
  }
}
